import styled, { keyframes } from "styled-components";
import React from "react";
import Spinner from "./Spinner";
import { lighten } from "polished";

type ButtonProps = JSX.IntrinsicElements["button"];

interface Props extends ButtonProps {
  isLoading: boolean;
  small?: boolean;
}

export const AddToCartButton = ({
  isLoading,
  children,
  small,
  ...props
}: React.PropsWithChildren<Props>) => (
  <Button {...props}>{isLoading ? <Spinner small={small} /> : children}</Button>
);

const ripple = keyframes`
0% {
  transform: scale(0, 0);
  opacity: 1;
}
20% {
  transform: scale(25, 25);
  opacity: 1;
}
100% {
  opacity: 0;
  transform: scale(40, 40);
}
`;
export const Button = styled.button`
  position: relative;
  overflow: hidden;
  background-color: ${({ theme, color }) => color || theme.colors.main};
  box-shadow: none;
  border: none;
  width: 100%;
  text-transform: uppercase;
  vertical-align: middle;
  transition: 0.3s;
  color: #fff;
  border: 1px solid transparent;
  letter-spacing: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  font-family: soleil, sans-serif;
  letter-spacing: 0.14em;
  font-weight: 600;
  width: 100%;
  height: 50px;
  &:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5%;
    height: 5px;
    background: rgba(255, 255, 255, 0.5);
    opacity: 0;
    border-radius: 100%;
    transform: scale(1, 1) translate3d(-50%, 0, 0);
    transform-origin: 50% 50%;
  }
  &:focus:not(:active)::after {
    animation: ${ripple} 1s ease-out;
  }
  &:hover {
    transition: 0.3s;
    cursor: pointer;

    background-color: ${({ theme, color }) =>
      lighten(0.05, color || theme.colors.main)};
  }
  &:focus {
    outline: 0px;
  }
  &[disabled] {
    background-color: #a3acaf;
    opacity: 0.65;
    &:hover {
      cursor: auto;
    }
  }
`;
