import React from "react";
import styled from "styled-components";
import { resizeImage } from "utils/image";

interface Props {
  src: string;
  alt: string;
  width?: number;
  height?: number;
  className?: string;
}

const ShopifyImage = styled(({ src, alt, width, height, className }: Props) => {
  const formattedUrl = resizeImage(src, { width, height });
  return <img className={className} src={formattedUrl} alt={alt} />;
})``;

export default styled(ShopifyImage)``;
